























import { Vue, Component, Watch } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import pieChart from '@/components/chart/pieChart.vue'
import { SeriesType } from '@/types/kyc'
import { formatRes } from '@/utils/tools'
import { fetchNowAs, fetchNowChart, fetchSuggestChart } from '@/api/portfolio'
import { childEduPlanSave, childfEduInfo, childfindInfo } from '@/api/child'
import { FivePlan } from '@/types/child'
import lineChart from '@/components/chart/lineChart.vue'
import { CommonMudule } from '@/store/modules/common'
import { AnalyModule } from '@/store/modules/analy'
import { cloneDeep } from 'lodash'
import variables from '@/styles/_variables.scss'
import { mixins } from 'vue-class-component'
import echarts from 'echarts'
import AnalyMixins from '@/utils/mixins/analy-mixins'
import tableTextBar from '@/components/devCom/tableTextBar.vue'

@Component({
  name: 'notBlockOne',
  components: {
    moduleHeader,
    pieChart,
    lineChart,
    tableTextBar
  }
})
export default class extends mixins(AnalyMixins) {
  customerName = sessionStorage.getItem('customerName')
    private asObj = {
      customerAssetAllocationTable: [],
      totalAssetAmount: 0,
      suggestAddUsd: 0,
      equivalentCny: 0
    }

    get basicSecurityObj() {
      console.log('AnalyModule.basicSecurityObj---', AnalyModule.basicSecurityObj)
      return AnalyModule.basicSecurityObj
    }

    get basicChildArr() {
      console.log('AnalyModule.basicChildArr view', AnalyModule.basicChildArr)
      return AnalyModule.basicChildArr
    }

    created() {
      this.initFetch()
    }

    selectType(type: any) {
      console.log('type', type)
    }

    reset() {
      this.$router.replace({
        // name: 'baseHome',
        path: '/basicSecurity/index',
        params: {
          name: 'reset'
        }})
    }
    Submit() {
      this.$router.push('/basicSecurity/basePlanDetail')
    }
    async initFetch() {
      this.getBasicSecurityObj()
      this.getBasicChildArr()
    }
}

<template>
  <section>
    <div class="left-container">
      <div class="left-wrapper-block alone-plan">
        <div v-loading="isLoading" class="left-wraper left-block" style="padding: 18px 47px;">
          <el-form v-if="summaryTableData" ref="asForm" label-width="80px" label-position="top">
            <div class="page-splite-flag">
              <module-header title="养老规划" />
              <div class="as-table">
                <el-table
                  :data="summaryTableDataStr"
                  border
                  style="width: 100%"
                >
                  <el-table-column
                    prop="totalPensionExpends"
                    align="center"
                  >
                    <template slot="header">
                      <p>
                        未来所需总支出（万元）
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="currentPensionAmounts"
                    align="center"
                  >
                    <template slot="header">
                      <p>
                        当前保障资产（万元）
                      </p>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="plan-detail-title">
                养老年金选择
              </div>
              <div class="remote" style="margin-bottom: 20px;">
                <el-select
                  v-model="remoteValue"
                  filterable
                  placeholder="请输入关键词"
                  :loading="searchLoading"
                  style="width: 512px;"
                  disabled
                  @change="remoteValueEvent"
                >
                  <el-option
                    v-for="item in remoteOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
                <!-- <i class="el-icon-document" /> -->
              </div>
              <div class="flex-row-between plan-money">
                <el-form-item v-enterNumber label="规划总保费">
                  <el-input
                    v-model="totalAmount"
                    class="select-class"
                    disabled
                    @blur="blurEnterTotalAmount"
                  >
                    <span slot="suffix" style="end-unit">元</span>
                  </el-input>
                </el-form-item>
                <el-form-item
                  v-enterNumber
                  label="缴费期限"
                >
                  <el-select
                    v-model="selectYear"
                    clearable
                    placeholder="请选择"
                    class="select-class"
                    disabled
                    @change="changeSelectYear"
                  >
                    <el-option
                      v-for="item in selectYearOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="`年缴费（占当下家庭年收入的：${incomePercentage || ''}%）`"
                >
                  <el-input
                    v-model="yearlyAmountStr"
                    class="select-class"
                    disabled
                  >
                    <span slot="suffix" style="end-unit">元</span>
                  </el-input>
                </el-form-item>
              </div>
              <div class="as-name">
                产品测算
              </div>
              <div v-if="statusticsDataStr && statusticsData" class="plan-money">
                <div class="flex-row-between">
                  <el-form-item
                    label="总保费"
                  >
                    <el-input
                      v-model="statusticsDataStr.totalPremium"
                      class="select-class"
                      disabled
                    >
                      <span slot="suffix" style="end-unit">元</span>
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="累计保证年金领取"
                  >
                    <el-input
                      v-model="statusticsDataStr.totalAnnuityDraw"
                      class="select-class"
                      disabled
                    >
                      <span slot="suffix" style="end-unit">元</span>
                    </el-input>
                  </el-form-item>
                  <el-form-item />
                  <!-- <el-form-item
                    v-enterNumber
                    label="累计中等红利领取"
                  >
                    <el-input
                      v-model="statusticsData.totalBonusDraw"
                      class="select-class"
                      disabled
                    >
                      <span slot="suffix" style="end-unit">元</span>
                    </el-input>
                  </el-form-item> -->
                </div>
                <el-divider />
                <div class="flex-row-between">
                  <el-form-item
                    label="期末生存总利益"
                  >
                    <el-input
                      v-model="statusticsDataStr.totalAmountDraw"
                      class="select-class"
                      disabled
                    >
                      <span slot="suffix" style="end-unit">元</span>
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    v-enterNumber
                    label="期末生存总利益/总保费"
                  >
                    <el-input
                      v-model="statusticsData.annuityMultiple"
                      class="select-class"
                      disabled
                    >
                      <span slot="suffix" style="end-unit">倍</span>
                    </el-input>
                  </el-form-item>
                  <el-form-item />
                </div>
              </div>
            </div>

            <div class="as-table page-splite-flag">
              <el-table
                :data="infoList"
                border
                style="width: 100%"
              >
                <el-table-column
                  prop="age"
                  align="center"
                >
                  <template slot="header">
                    <p>
                      年龄
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="yearMnyStr"
                  align="center"
                >
                  <template slot="header">
                    <p>
                      年缴保费（元）
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="fixMnyStr"
                  align="center"
                >
                  <template slot="header">
                    <p>
                      保证年金领取额（元）
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="cashValueStr"
                  align="center"
                >
                  <template slot="header">
                    <p>
                      现金价值（元）
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="bonusStr"
                  align="center"
                >
                  <template slot="header">
                    <p>
                      中档红利（元）
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="lifeBenefitStr"
                  align="center"
                >
                  <template slot="header">
                    <p>
                      生存总利益（元）
                    </p>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="stackOption" class="page-splite-flag">
              <div style="font-size: 14px;color: #333333;margin: 20px 0 0 0;">
                产品现金流分布图
              </div>
              <div>
                <stack-chart
                  id="stackChart"
                  class="chart-pie"
                  width="875px"
                  height="360px"
                  :option="stackOption"
                />
              </div>
            </div>
            <div v-if="showProductLine && insuranceParam.insuranceDividend" class="page-splite-flag">
              <div style="font-size: 14px;color: #333333;margin: 20px 0 0 0;">
                产品长期利益演示图
              </div>
              <line-chart
                id="productLine"
                class="chart-pie"
                width="875px"
                height="360px"
                :option="barProductOption"
              />
            </div>
          </el-form>
        </div>
      </div>
      <div v-if="automaticInvestment" class="left-main-block2 page-splite-flag">
        <module-header title="定投产品选择" />
        <div class="left-wraper left-block" style="padding: 18px 47px;">
          <div class="frequency-wraper" style="margin-bottom: 20px;">
            <span class="as-label">
              计划定投频次：
            </span>
            <el-radio-group
              v-model="planData.investPlanAutoFreq"
              @change="changeInvestPlanEvent"
            >
              <el-radio label="Monthly" disabled>
                月度
              </el-radio>
              <el-radio label="Quarterly" disabled>
                季度
              </el-radio>
              <el-radio label="Annual" disabled>
                年度
              </el-radio>
            </el-radio-group>
          </div>
          <el-form label-width="80px" label-position="top">
            <div class="flex-row-between">
              <div>
                <el-form-item label="每期投资：">
                  <el-input
                    v-model="planData.investAmount"
                    v-enterNumber
                    disabled
                    @blur="changeInvestPlanEvent"
                  >
                    <span slot="prefix" class="prefix-icon">¥</span>
                    <span slot="suffix" style="end-unit">元</span>
                  </el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="每期投资占年收入的：">
                  <el-input
                    v-model="planData.incomePercentage"
                    v-enterNumber
                    disabled
                  >
                    <span slot="suffix" style="end-unit">%</span>
                  </el-input>
                </el-form-item>
              </div>
              <el-form-item />
            </div>
            <div class="flex-row-start">
              <div>
                <el-form-item label="产品选择：" style="margin-right: 74px;">
                  <el-select
                    v-model="planData.productId"
                    filterable
                    placeholder="请选择"
                    :remote-method="remoteMethod"
                    :loading="loading"
                    style="width: 512px;"
                    disabled
                    @change="changeInvestPlanEvent"
                  >
                    <el-option
                      v-for="item in selectOptions"
                      :key="item.id"
                      :label="item.productName"
                      :value="item.id"
                    />
                  </el-select>
                  <!-- <i class="el-icon-document" /> -->
                </el-form-item>
              </div>
              <div>
                <el-form-item label="预计年化收益率：">
                  <el-input
                    v-model="planData.productExpectedReturn"
                    v-enterNumber
                    disabled
                  >
                    <span slot="suffix" style="end-unit">%/年</span>
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <div class="page-splite-flag">
            <div class="as-name">
              定投本息测算：{{ planData.allTotalStr }}
            </div>
            <line-chart
              id="planLine"
              class="chart-pie"
              width="875px"
              height="360px"
              :option="barPlanOption"
            />
            <span style="color: #969696;font-size: 12px;">
              *仅做参考展示，不代表未来实际利益
            </span>
          </div>
        </div>
      </div>
      <div class="left-main-block2  page-splite-flag">
        <module-header title="总览" />
        <div
          class="left-wraper left-block"
          style="padding: 18px 47px;"
        >
          <div class="as-table">
            <el-table
              :data="overviewTableDataStr"
              border
              style="width: 100%"
            >
              <el-table-column
                prop="shortfall"
                align="center"
              >
                <template slot="header">
                  <p>
                    规划前未来资金需求缺口（万元）
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                prop="yearAmount"
                align="center"
              >
                <template slot="header">
                  <p>
                    年金领取总金额（万元）
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                v-if="automaticInvestment"
                prop="casSurelyTotalAmount"
                align="center"
              >
                <template slot="header">
                  <p>
                    定投总收益（万元）
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                prop="theMomentGap"
                align="center"
              >
                <template slot="header">
                  <p>
                    当下养老缺口（万元）
                  </p>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import {
  fetchPensionFind,
  fetchPensionSummary,
  pensionInsuranceList,
  pensionInsuranceSave,
  pensionInsuranceStatistics,
  pensionInsuranceInfoList,
  pensionTransaction,
  fetchPensionProductList,
  fetchPensionInvestmentPlan,
  pensionInvestmentSave,
  pensionPlanChart,
  pensionInvestmentDelete
} from '@/api/pension'
import { getFinancialAnalysisInfo } from '@/api/articles'
import { CommonMudule } from '@/store/modules/common'
import SplitPane from 'vue-splitpane'
import moduleHeader from '@/components/moduleHeader/index.vue'
import lineChart from '@/components/chart/lineChart.vue'
import stackChart from '@/components/chart/stackChart.vue'
import variables from '@/styles/_variables.scss'
import formItem from '@/components/formItem/index.vue'
import { cloneDeep } from 'lodash'
import { delcommafy, comdify } from '@/utils/validate'

@Component({
  name: 'printPensionPlanDetail',
  components: {
    SplitPane,
    moduleHeader,
    lineChart,
    stackChart,
    formItem
  }
})

export default class extends Vue {
  id = ''
  isLoading = false
  loading = false
  hoverBoxShow = false
  showProductLine = false
  automaticInvestment = false // 是否加入定投
  summaryTableData = []
  summaryTableDataStr = []
  inAmount = 0
  totalAmount = ''
  yearlyAmount = ''
  yearlyAmountStr = ''
  incomePercentage = ''
  overviewTableData = []
  overviewTableDataStr = []
  selectOptions = []
  selectValue = ''
selectYearOptions = []
  casSurelyTotalAmount = 0
  selectYear = ''
  remoteOptions = []
  remoteValue = ''
  searchLoading = false
  insuranceParam = {
    insuranceType: 'Pension',
    orderField: 'ranking',
    universalAccount: true,
    insuranceDividend: true
  }
  statusticsData = {
    annuityMultiple: '',
    totalAmountDraw: '',
    totalAnnuityDraw: '',
    totalBonusDraw: '',
    totalPremium: ''
  }
  statusticsDataStr = {
    annuityMultiple: '',
    totalAmountDraw: '',
    totalAnnuityDraw: '',
    totalBonusDraw: '',
    totalPremium: ''
  }
  infoList = []
  stackOption = null
  insuranceList = []
  barProductOption = {
    color: [variables.chartColor],
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        axisLabel: { // 重点在这一块，其余可以忽略
          interval: 10, // 这个一定要有，别忘记了
          textStyle: {
            color: '#333',
            fontSize: 12
          }
        },
        data: []
      }
    ],
    yAxis: {
      type: 'value'
    },
    tooltip: {
    },
    series: [{
      type: 'line',
      barMaxWidth: 80,
      barGap: '150%',
      data: []
    }]
  }
  barPlanOption = {
    color: [variables.chartColor],
    legend: {
      left: 'center'
    },
    grid: {
      left: '10%',
      right: '2%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false, alignWithLabel: true },
        axisLabel: { // 重点在这一块，其余可以忽略
          interval: 20, // 这个一定要有，别忘记了
          textStyle: {
            color: '#333',
            fontSize: 12
          }
        },
        axisLine: {
          onZero: false,
          show: true,
          interval: 0,
          lineStyle: {
            color: '#333'
          }
        },
        data: []
      }
    ],
    yAxis: {
      axisLine: { show: false },
      axisTick: { show: false },
      type: 'value',
      name: '产品长期利益演示图',
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed'
        }
      }
    },
    tooltip: {
    },
    series: [{
      type: 'line',
      name: '',
      symbol: 'circle', // 折线点设置为实心点
      symbolSize: 10, // 折线点的大小
      itemStyle: {
        normal: {
          color: [variables.chartColor], // 折线点的颜色
          lineStyle: {
            color: [variables.chartColor]// 折线的颜色
          }
        }
      },
      data: []
    }]
  }
  planData = {
    insuranceType: 'Pension',
    investPlanAutoFreq: 'Monthly',
    investAmount: '',
    incomePercentage: '',
    productId: '',
    id: '',
    productExpectedReturn: '',
    allTotal: '',
    allTotalStr: ''
  }
  created() {
    this.preFetch()
    this.fetchInsuranceList()
  }
  resize() {
    // Handle resize
  }
  accAdd(arg1, arg2) { // 加法
    let r1
    let r2
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    const m = Math.pow(10, Math.max(r1, r2))
    return (arg1 * m + arg2 * m) / m
  }
  accSub(arg1, arg2) { // 减法
    let r1
    let r2
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    const m = Math.pow(10, Math.max(r1, r2))
    const n = (r1 >= r2) ? r1 : r2
    return Number(((arg1 * m - arg2 * m) / m).toFixed(n))
  }
  accDiv(arg1, arg2) { // 乘法
    let c = 0
    const d = arg1.toString()
    const e = arg2.toString()
    try {
      c += d.split('.')[1].length
    } catch (f) {
      // s
    }
    try {
      c += e.split('.')[1].length
    } catch (f) {
      // s
    }
    return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c)
  }
  accMul(arg1, arg2) { // 除法
    let t1 = 0
    let t2 = 0
    try {
      t1 = arg1.toString().split('.')[1].length
    } catch (e) {
      // s
    }
    try {
      t2 = arg2.toString().split('.')[1].length
    } catch (e) {
      // s
    }
    const r1 = Number(arg1.toString().replace('.', ''))
    const r2 = Number(arg2.toString().replace('.', ''))
    return (r1 / r2) * Math.pow(10, t2 - t1)
  }
  backToEdit() {
    pensionInvestmentDelete({ customerId: CommonMudule.customerId }).then((res) => {
      if (res.success && res.statusCode === 0) {
        this.$message.success('操作成功!')
        setTimeout(() => {
          this.$router.replace({
            path: '/pension/plan'
          })
        }, 1500)
      } else {
        this.$message.error(res.errorMsg)
      }
    })
  }
  countPlanData() {
    if (this.planData.investAmount) {
      this.planData.incomePercentage = this.accDiv(this.accMul(Number(this.planData.investAmount), (Number(this.inAmount) || 1)), 100).toFixed(2)
    }
    if (this.planData.productId) {
      this.selectOptions.forEach((item) => {
        if (item.id === this.planData.productId) {
          this.planData.productExpectedReturn = item.productExpectedReturn
        }
      })
    }
  }
  changeInvestPlanEvent() { // 保存定投信息
    this.countPlanData()
    if (this.planData.investPlanAutoFreq && this.planData.investAmount && this.planData.productId) {
      const data = cloneDeep(this.planData)
      data.customerId = CommonMudule.customerId
      pensionInvestmentSave(data).then(res => {
        if (res.success && res.statusCode === 0) {
          this.planData.id = res.data.id
          this.planChart()
        }
      })
    }
  }
  async remoteMethod(query) {
    const param = {
      productName: undefined,
      enableAutomaticInvest: true
    }
    if (query) {
      param.productName = query
    }
    const res = await fetchPensionProductList(param)
    if (res.success && res.statusCode === 0) {
      this.selectOptions = res.data.content
    } else {
      this.selectOptions = []
    }
  }
  // 获取table等数据
  async fetchTable() {
    this.isLoading = true
    const res = await Promise.all([pensionInsuranceStatistics({ customerId: CommonMudule.customerId }), pensionInsuranceInfoList({ customerId: CommonMudule.customerId })])
    let statusticsData = null
    const statusticsDataStr = {}
    let infoList = []
    const shortfall = this.accSub(Number(this.summaryTableData[0].totalPensionExpends), Number(this.summaryTableData[0].currentPensionAmounts))
    let yearAmount = 0
    let theMomentGap = 0
    if (res[0].success && res[0].statusCode === 0) {
      statusticsData = res[0].data
      statusticsDataStr.totalPremium = comdify(Number(statusticsData.totalPremium || 0).toString())
      statusticsDataStr.totalAnnuityDraw = comdify(Number(statusticsData.totalAnnuityDraw || 0).toString())
      statusticsDataStr.totalAmountDraw = comdify(Number(statusticsData.totalAmountDraw || 0).toString())
    }
    if (res[1].success && res[1].statusCode === 0) {
      infoList = res[1].data
      if (infoList && infoList.length) {
        const productDataList = []
        const productSeriesList = []
        const stackDataYearMny = []
        const stackDataYear = []
        const stackDataBonus = []
        const stackAge = []
        let showProductLine = false
        infoList.forEach((item) => {
          yearAmount = this.accAdd(Number(item.fixMny), yearAmount)
          productDataList.push(item.age)
          productSeriesList.push(item.universalAccountValue)
          stackDataYearMny.push(item.fixMny)
          stackDataYear.push(-item.yearMny)
          stackDataBonus.push(item.bonus)
          if (item.bonus) {
            showProductLine = true
          }
          stackAge.push(item.age)
          item.cashValueStr = comdify(Number(item.cashValue || 0).toString())
          item.fixMnyStr = comdify(Number(item.fixMny || 0).toString())
          item.yearMnyStr = comdify(Number(item.yearMny || 0).toString())
          item.bonusStr = comdify(Number(item.bonus || 0).toString())
          item.lifeBenefitStr = comdify(Number(item.lifeBenefit || 0).toString())
        })
        this.barProductOption.xAxis[0].data = productDataList
        this.barProductOption.series[0].data = productSeriesList
        this.showProductLine = showProductLine
        this.setStackOption(stackDataYearMny, stackDataYear, stackDataBonus, stackAge)
      }
    }
    if (this.remoteValue && this.insuranceList && this.insuranceList.length) {
      let str = ''
      this.insuranceList.forEach((item) => {
        if (item.id === this.remoteValue) {
          str = item.paymentTerms
        }
      })
      this.initSelectKeys(str)
    }
    if (this.automaticInvestment) {
      const testNum = shortfall - yearAmount - this.casSurelyTotalAmount
      theMomentGap = testNum > 0 ? Math.abs(this.accSub(this.accSub(shortfall, yearAmount), this.casSurelyTotalAmount)) : 0
    } else {
      const testNum = shortfall - yearAmount
      theMomentGap = testNum > 0 ? Math.abs(this.accSub(shortfall, yearAmount)) : 0
    }
    this.statusticsData = statusticsData
    this.statusticsDataStr = statusticsDataStr
    this.infoList = infoList
    this.overviewTableData = [
      {
        shortfall: shortfall,
        yearAmount: yearAmount ? yearAmount.toFixed(2) : 0,
        casSurelyTotalAmount: this.casSurelyTotalAmount,
        theMomentGap: theMomentGap
      }
    ]
    this.overviewTableDataStr = [
      {
        shortfall: this.accMul(Number(shortfall || 0), 10000).toFixed(2),
        yearAmount: this.accMul(Number(yearAmount || 0), 10000).toFixed(2),
        casSurelyTotalAmount: this.accMul(Number(this.casSurelyTotalAmount || 0), 10000).toFixed(2),
        theMomentGap: this.accMul(Number(theMomentGap || 0), 10000).toFixed(2)
      }
    ]
    this.isLoading = false
  }
  setStackOption(stackDataYearMny, stackDataYear, stackDataBonus, stackAge) {
    let max = 0
    let min = 0
    stackDataYearMny.forEach((item, idx) => {
      const bonus = stackDataBonus[idx] ? stackDataBonus[idx] : 0
      if (this.accAdd(item, bonus) > max) {
        max = this.accAdd(item, bonus)
      }
    })
    stackDataYear.forEach((item) => {
      if (item > max) {
        max = item
      }
      if (item < min) {
        min = item
      }
    })
    const len = stackAge.length
    let wid = 20
    if (len) {
      wid = Math.floor(this.accMul(700, len))
      wid = wid > 10 ? wid - 6 : 4
    }
    this.stackOption = {
      color: ['#003C9D ', '#880000', '#227700'],
      tooltip: {
        trigger: 'axis',
        axisPointer: { // 坐标轴指示器，坐标轴触发有效
          type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      legend: {
        data: ['年保费', '固定年金领取', '中等红利'],
      },
      xAxis: [{
        axisLabel: {
          interval: 10,
          onZero: false
        },
        data: stackAge
      }],
      yAxis: {
        max: max,
        min: min,
        scale: true,
        axisLine: {
          onZero: false,
          show: false,
          interval: 0
        },
        stackLabels: { // 堆叠数据标签
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: 'gray'
          }
        }
      },
      series: [
        {
          name: '年保费',
          type: 'bar',
          stack: 'vistors',
          barWidth: wid,
          data: stackDataYear
        },
        {
          name: '固定年金领取',
          type: 'bar',
          stack: 'vistors',
          barWidth: wid,
          data: stackDataYearMny
        }, {
          name: '中等红利',
          type: 'bar',
          stack: 'vistors',
          barWidth: wid,
          data: stackDataBonus
        }
      ]
    }
  }
  // 保存保险
  saveInsurance() {
    this.isLoading = true
    this.yearlyAmount = this.accMul(Number(this.totalAmount), Number(this.selectYear))
    this.yearlyAmountStr = comdify(Number(this.yearlyAmount || 0).toString())
    this.incomePercentage = this.accDiv(this.accMul(Number(this.yearlyAmount), (Number(this.inAmount) || 1)), 100).toFixed(2)
    const param = {
      customerId: CommonMudule.customerId,
      insuranceId: this.remoteValue,
      insuranceType: 'Pension',
      totalAmount: this.totalAmount,
      years: this.selectYear,
      yearlyAmount: this.yearlyAmount,
      incomePercentage: this.incomePercentage
    }
    if (this.id) {
      param.id = this.id
    }
    pensionInsuranceSave(param).then(res => {
      if (res.success && res.statusCode === 0) {
        this.isLoading = false
        this.id = res.data.id
        this.fetchTable()
      } else {
        this.$message.error(res.errorMsg)
        this.isLoading = false
      }
    })
  }
  // 缴费期限变化事件
  changeSelectYear() {
    if (this.selectYear && this.totalAmount) {
      this.saveInsurance()
    }
  }
  // 规划总金额焦点离开
  blurEnterTotalAmount() {
    if (this.selectYear && this.totalAmount) {
      this.saveInsurance()
    }
  }
  initSelectKeys(str) {
    const list = []
    const arr = str.split(',')
    arr.forEach((item) => {
      if (item === '1') {
        list.push({
          label: '趸交',
          value: '1'
        })
      } else {
        list.push({
          label: item + '年',
          value: item + ''
        })
      }
    })
    this.selectYearOptions = list
  }
  remoteValueEvent() {
    if (this.remoteValue) {
      let str = ''
      this.insuranceList.forEach((item) => {
        if (item.id === this.remoteValue) {
          str = item.paymentTerms
        }
      })
      this.initSelectKeys(str)
      this.statusticsData = {}
      this.infoList = []
      this.stackOption = null
      this.selectYear = ''
    }
  }
  // 获取产品列表
  async fetchInsuranceList(val) {
    this.isLoading = true
    if (val === 'ranking' || val === 'cashValue') {
      this.insuranceParam.orderField = val
    }
    const data = cloneDeep(this.insuranceParam)
    if (data.universalAccount) {
      delete data.universalAccount
    }
    if (data.insuranceDividend) {
      delete data.insuranceDividend
    }
    const res = await pensionInsuranceList(data)
    let remoteOptions = []
    if (res.success && res.statusCode === 0) {
      if (res.data && res.data.length) {
        remoteOptions = res.data
      }
    }
    this.remoteOptions = remoteOptions
    this.insuranceList = res.data || []
    if (this.remoteValue) {
      let str = ''
      this.insuranceList.forEach((item) => {
        if (item.id === this.remoteValue) {
          str = item.paymentTerms
        }
      })
      this.initSelectKeys(str)
    }
    this.isLoading = false
  }
  planChart() {
    pensionPlanChart({ customerId: CommonMudule.customerId }).then((res) => {
      const xAxisData = []
      const seriesData = []
      if (res.success && res.statusCode === 0 && res.data && res.data.length) {
        res.data.forEach((item) => {
          xAxisData.push(item.term)
          seriesData.push(item.amount)
        })
      }
      this.barPlanOption.xAxis[0].data = xAxisData
      this.barPlanOption.series[0].data = seriesData
      this.casSurelyTotalAmount = res.data && res.data.length ? res.data[res.data.length - 1].amount : 0
      this.planData.allTotal = this.casSurelyTotalAmount
      this.planData.allTotalStr = comdify(Number(this.casSurelyTotalAmount || 0).toString())
      this.fetchTable()
    })
  }
  // 初始化定投
  async initPlan() {
    const res = await fetchPensionInvestmentPlan({ customerId: CommonMudule.customerId })
    if (res.success && res.statusCode === 0) {
      if (res.data) {
        this.planData = res.data
        this.planChart()
      }
      this.remoteMethod()
    } else {
      this.$message.error(res.errorMsg)
      this.remoteMethod()
    }
  }
  // 初始化页面
  async initFetch() {
    const res = await fetchPensionFind({ customerId: CommonMudule.customerId })
    if (res.success && res.statusCode === 0) {
      if (res.data) {
        this.automaticInvestment = res.data.automaticInvestment
        if (this.automaticInvestment) {
          this.initPlan()
        }
      }
    } else {
      this.$message.error(res[0].errorMsg)
    }
    const resSummary = await fetchPensionSummary({ customerId: CommonMudule.customerId })
    if (resSummary.success && resSummary.statusCode === 0) {
      if (resSummary.data) {
        this.summaryTableData = [resSummary.data]
        this.summaryTableDataStr = [
          {
            totalPensionExpends: this.accMul(Number(resSummary.data.totalPensionExpends || 0), 10000).toFixed(2),
            currentPensionAmounts: this.accMul(Number(resSummary.data.currentPensionAmounts || 0), 10000).toFixed(2)
          }
        ]
      }
    } else {
      this.$message.error(resSummary.errorMsg)
    }
    const resFinancial = await getFinancialAnalysisInfo({ customerId: CommonMudule.customerId })
    if (resFinancial.success && resFinancial.statusCode === 0) {
      this.inAmount = resFinancial.data.customerMember.inAmount
    } else {
      this.$message.error(resFinancial.errorMsg)
    }
    if (this.id) {
      this.fetchTable()
    }
  }
  async preFetch() {
    const res = await pensionTransaction({ customerId: CommonMudule.customerId })
    if (res.success && res.statusCode === 0 && res.data) {
      this.id = res.data.id
      this.totalAmount = res.data.totalAmount
      this.yearlyAmount = res.data.yearlyAmount
      this.yearlyAmountStr = comdify(Number(res.data.yearlyAmount || 0).toString())
      this.incomePercentage = res.data.incomePercentage ? Number(res.data.incomePercentage || 0).toFixed(2) : res.data.incomePercentage
      this.selectYear = this.isNum(res.data.years) ? res.data.years.toString() : ''
      this.remoteValue = res.data.insuranceId
    }
    this.initFetch()
  }
  isNum(val) {
    return typeof val === 'number' && !isNaN(val)
  }
}
</script>

<style lang="scss">
  .alone-plan {
    .has-gutter tr th{
      background: $tbBg;
      color: $primaryColor;
    }
    .has-gutter tr th:hover{
      background-color: $primaryColor;
      color: $fff;
    }
  }
</style>
<style lang="scss" scoped>
.left-container{
  background-color: $bgGray;
  .left-wrapper-block{
    background: $fff;
    .pension-form-wrapper{
      padding-top: 30px;
      margin-bottom: 30px;
    }
  }
  .left-main-block2{
    background: $fff;
    margin-top: 16px;
    .intro{
      color: $primaryColor;
      margin-right: 20px;
    }
  }
  .plan-detail-title{
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.as-name{
  margin-top: 10px;
}
.chart-pie{
   margin-top: 30px;
}
.as-table{
  max-width: 820px;
  margin:10px auto;
}
</style>

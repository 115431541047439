import request from '@/utils/request'
import {
  FamilyCommonParam,
  FamilyInsuranceParam,
  FamilySummaryParam,
  FetchInsuranceParam,
  SaveInsuranceParam,
  FamilyITransactionParam
} from '@/types/family'

/**
 * 获取家庭支柱id
 */
export const fetchFamilyMainMember = (params: FamilyCommonParam) =>
  request({
    url: '/blackcat/family/main/member',
    method: 'get',
    params
  })

/**
 * 获取家庭成员id
 */
export const fetchFamilyMembers = (params: FamilyCommonParam) =>
  request({
    url: '/blackcat/family/members',
    method: 'get',
    params
  })

/**
 * 寿险统计 产品测算
 */
export const fetchFamilyLifeInsurance = (params: FamilyInsuranceParam) =>
  request({
    url: '/blackcat/family/life/insurance/statistics',
    method: 'get',
    params
  })

/**
 * 重疾统计 产品测算
 */
export const fetchFamilyCriticalInsurance = (params: FamilyInsuranceParam) =>
  request({
    url: '/blackcat/family/critical/illness/insurance/statistics',
    method: 'get',
    params
  })

/**
 * 寿险统计 table
 */
export const fetchFamilyLifeInsuranceInfos = (params: FamilyInsuranceParam) =>
  request({
    url: '/blackcat/family/life/insurance/infos',
    method: 'get',
    params
  })

/**
 * 重疾统计 table
 */
export const fetchFamilyCriticalInsuranceInfos = (params: FamilyInsuranceParam) =>
  request({
    url: '/blackcat/family/critical/illness/insurance/infos',
    method: 'get',
    params
  })

/**
 * 获取总览 code 参考财富体检
 */
export const fetchFamilySummary = (params: FamilySummaryParam) =>
  request({
    url: '/blackcat/family/summary',
    method: 'get',
    params
  })

// 查询保险列表
export const familyInsuranceList = (params: FetchInsuranceParam) =>
  request({
    url: '/blackcat/insurance/list',
    method: 'get',
    params
  })

// 保存保险产品
export const familyInsuranceSave = (params: SaveInsuranceParam) =>
  request({
    url: '/blackcat/insurance/transaction/save',
    method: 'POST',
    data: params
  })

// 查询家庭基础保障
export const familyTransaction = (params: FamilyITransactionParam) =>
  request({
    url: '/blackcat/family/transaction',
    method: 'get',
    params
  })

// 删除保险产品
export const familyInsuranceDelete = (params: FamilyCommonParam) =>
  request({
    url: '/blackcat/family/delete',
    method: 'POST',
    params
  })

/**
 * 子女排序
 */
export const fetchChildrenSort = (params: FamilyCommonParam) =>
  request({
    url: '/blackcat/customer/child',
    method: 'get',
    params
  })

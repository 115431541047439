





































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ChartRes, AnalyGraph } from '@/types/analy'
import pieChart from '@/components/chart/pieChart.vue'
import gaugeChart from '@/components/chart/gaugeChart.vue'
import { SeriesType } from '@/types/kyc'
import { formatRes } from '@/utils/tools'

@Component({
  name: 'tablePie',
  components: {
    pieChart,
    gaugeChart
  }
})
export default class extends Vue {
  @Prop({ required: true }) data!: ChartRes
  @Prop({ required: false, default: 'Row' }) tableType!: string
  @Prop({ required: false, default: 'pie' }) chartType!: string
  @Prop({ required: true, }) keyName!: string

  private seriesType: SeriesType = {
    radius: [0, '55%'],
    type: 'pie',
    top: 10,
    hoverAnimation: true,
    data: []
  }

  loading = true

  @Watch('data', { immediate: true })
  getPropData(v: ChartRes) {
    if (v.id) {
      this.loading = false
      if (this.chartType === 'pie') {
        this.seriesType.data = formatRes(v.assetAnalysisItemGraph, 'itemName', 'proportion')
      }
    }
  }
}
Vue.component('coms', {
  props: {
    ctx: {
      type: String,
      default: ''
    }
  },
  render(h) {
    const com = Vue.extend({
      template: `<div>${this.ctx === null ? '' : this.ctx}</div>`
    })
    return h(com)
  }
})

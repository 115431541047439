






















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ChartRes, AnalyGraph } from '@/types/analy'
import gaugeChart from '@/components/chart/gaugeChart.vue'
import { SeriesType } from '@/types/kyc'
import { formatRes } from '@/utils/tools'
import variables from '@/styles/_variables.scss'
import lineChart from '@/components/chart/lineChart.vue'
import echarts from 'echarts'
import { cloneDeep } from 'lodash'

@Component({
  name: 'tableBar',
  components: {
    lineChart,
    gaugeChart
  }
})
export default class extends Vue {
  @Prop({ required: true }) data!: ChartRes
  @Prop({ required: false, default: 'Row' }) tableType!: string
  @Prop({ required: false, default: 'bar' }) chartType!: string
  @Prop({ required: false, }) keyName!: string

  loading = true

  @Watch('data', { immediate: true, deep: true })
  getPropData(v: ChartRes) {
    if (v.id) {
      this.loading = false
    }
  }
}

Vue.component('coms', {
  props: {
    ctx: {
      type: String,
      default: ''
    }
  },
  render(h) {
    const com = Vue.extend({
      template: `<div>${this.ctx === null ? '' : this.ctx}</div>`
    })
    return h(com)
  }
})

import request from '@/utils/request'
import { FivePlan, InsuranceList, saveChild } from '@/types/child'
import { CustomInfo } from '@/types/common'

export const childfindInfo = (params: any) =>
  request({
    url: '/blackcat/childrenedu/find',
    method: 'get',
    params
  })
// 子女信息概览
export const childrensList = (params: CustomInfo) =>
  request({
    url: '/blackcat/childrenedu/summary',
    method: 'get',
    loading: false,
    params
  })
  // 年缴保费信息表
export const childfInsurance = (params: CustomInfo) =>
  request({
    url: '/blackcat/childrenedu/insurance/info/list',
    method: 'get',
    loading: false,
    params
  })
// 查询保险统计
export const childfStatistics = (params: CustomInfo) =>
  request({
    url: '/blackcat/childrenedu/insurance/statistics',
    method: 'get',
    loading: false,
    params
  })
  // 获取定投信息
export const childfInvestment = (params: CustomInfo) =>
  request({
    url: '/blackcat/childrenedu/investment/plan/auto',
    method: 'get',
    params,
  })
// 子女信息
export const childfEduInfo = (params: CustomInfo) =>
  request({
    url: '/blackcat/customer/child',
    method: 'get',
    loading: false,
    params
  })
  // 是否为5岁计划保存
export const childEduPlanSave = (params: FivePlan) =>
  request({
    url: '/blackcat/childrenedu/save',
    method: 'POST',
    data: params
  })
// 查询保险列表
export const childfInsuranceList = (params: InsuranceList) =>
  request({
    url: '/blackcat/insurance/list',
    method: 'get',
    loading: false,
    params
  })
  // 保存保险产品
export const childfInsuranceSave = (params: saveChild) =>
  request({
    url: '/blackcat/insurance/transaction/save',
    method: 'POST',
    data: params
  })
// 读取综合评分那部分信息
export const childTransaction = (params: CustomInfo) =>
  request({
    url: '/blackcat/childrenedu/transaction',
    method: 'get',
    loading: false,
    params
  })

// 保存定投信息
export const childInvestmentSave = (params: any) =>
  request({
    url: '/blackcat/investment/plan/auto/save',
    method: 'POST',
    data: params
  })

// 读取最后一页定投折线图
export const childLineChart = (params: CustomInfo) =>
  request({
    url: '/blackcat/childrenedu/investment/plan/auto/chart',
    method: 'get',
    loading: false,
    params
  })
  // 删除当前页面的信息
export const childEduDelete = (params: CustomInfo) =>
  request({
    url: '/blackcat/childrenedu/delete',
    method: 'POST',
    params
  })







































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import lineChart from '@/components/chart/lineChart.vue'
import variables from '@/styles/_variables.scss'
import { SeriesType } from '@/types/kyc'
import { validateMax, calcRMBDollars, efn, ctefn } from '@/utils/tools'
import { PortMudule } from '@/store/modules/portfolio'
import { CommonMudule } from '@/store/modules/common'
import { savePlan, getPlan } from '@/api/portfolio'
import { Freq, InvestPlan, RiskType } from '@/types/portfolio'
import { ElForm } from 'element-ui/types/form'
import { seriesBarColor } from '@/store/modules/option'
import { rmbFilter, comdifyKill } from '@/utils/filter'
import { comdify } from '@/utils/validate'

@Component({
  name: 'setPlan',
  components: {
    moduleHeader,
    lineChart
  }
})
export default class extends Vue {
    comdifyKill = comdifyKill
    validateMax = validateMax
    calcRMBDollars = calcRMBDollars
    isDisable = true
    freqNum = 1
    freqObj = {
      'Annual': 12,
      'Quarterly': 3,
      'Monthly': 1
    }
    private hoverBoxShow:boolean = false
    private planObj: InvestPlan = {
      riskType: PortMudule.riskType,
      alone: PortMudule.alone,
      id: '',
      customerId: CommonMudule.customerId,
      deleted: false,
      durationYears: 2, // 本次投资持续时间：
      investmentAmount: 0, // 本次单笔投资金额
      investPlanAutoFreq: 'Monthly', // 计划定投频次：
      equipDollars: 0, // 约合美金：
      investPlanAutoTermAmount: 0, // 每期投资：
      investPlanAutoAmount: 0, // 计划定投总金额：
      planEquipDollars: 0, // 约合美金：
      planEarnings: 0, // 计划预期未来收益：
      assumeInflationRate: 2, // 假定通胀率：
      planEarningsDeductInflation: 0 // 扣除通胀后未来收益：
    }
    barOption = {
      grid: {
        bottom: 55,
        left: 120,
      },
      color: seriesBarColor,
      xAxis: [
        {
          type: 'category',
          axisTick: { show: false },
          data: ['预设投资总金额', '本计划的预计未来价值']
        }
      ],
      yAxis: {
        type: 'value',
        splitLine: {
          show: false
        }
      },
      tooltip: {
      },
      series: [{
        type: 'bar',
        barMaxWidth: 80,
        barGap: '150%',
        data: [{
          name: '预设投资总金额',
          value: 0,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color: '#2179FF',
          }
        },
        {
          name: '本计划的预计未来价值',
          value: 0,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color: '#48CBCB',
          }
        }]

      }]
    }
    created() {
      this.initFetch()
    }

    initFetch() {
      this.getInvest()
    }

    @Watch('planObj.durationYears', { deep: true })
    private calcDurationYears(v: number) {
      this.calcEfn()
      this.caclCtefn()
      this.calcPlanTotal()
      this.changeBtnStatus()
    }

    @Watch('planObj.investmentAmount', { deep: true })
    private calcEquipDollars(v: number) {
      this.planObj.equipDollars = this.calcRMBDollars(v)
      this.calcEfn()
      this.caclCtefn()
      this.changeBtnStatus()
    }

    @Watch('planObj.investPlanAutoAmount', { deep: true })
    private calcPlanDollars(v: number) {
      this.planObj.planEquipDollars = this.calcRMBDollars(v)
    }

    @Watch('planObj.investPlanAutoFreq', { deep: true })
    private calcInvestPlanAutoFreq(v: number) {
      // this.freqNum = this.freqObj[v]
      this.calcEfn()
      this.caclCtefn()
      this.calcPlanTotal()
      this.changeBtnStatus()
    }

    @Watch('planObj.investPlanAutoTermAmount', { deep: true })
    private calcInvestPlanAutoTermAmount(v: number) {
      this.calcEfn()
      this.caclCtefn()
      this.calcPlanTotal()
      this.changeBtnStatus()
    }

    private calcInvestPlanAssumeInflationRate(v: number) {
      this.calcEfn()
      this.caclCtefn()
      this.calcPlanTotal()
      this.changeBtnStatus()
    }

    changeBtnStatus() {
      if ((this.planObj.durationYears > 0 && this.planObj.investmentAmount > 0) || (this.planObj.durationYears > 0 && this.planObj.investPlanAutoTermAmount && this.planObj.investPlanAutoTermAmount > 0)) {
        if (PortMudule.alone) {
          if (PortMudule.riskType) {
            this.isDisable = false
          }
        } else {
          this.isDisable = false
        }
      } else {
        this.isDisable = true
      }
    }

    get riskType() {
      return PortMudule.riskType
    }

   @Watch('riskType')
    private caclRisk(v: RiskType) {
      if (v) {
        this.planObj.riskType = v
        this.changeBtnStatus()
      }
    }

   setOption(v?: any) {
     this.barOption.series[0].data[0].value = this.planObj.investPlanAutoAmount + Number(this.planObj.investmentAmount)
     this.barOption.series[0].data[1].value = this.planObj.planEarnings
     this.changeBtnStatus()
   }

   calcPlanTotal() {
     this.planObj.investPlanAutoAmount = this.planObj.investPlanAutoTermAmount * (12 / this.freqObj[this.planObj.investPlanAutoFreq]) * this.planObj.durationYears
   }

   // 计划预期未来收益：
   calcEfn() {
     //  (x:number, y: number, r: number, n: number, c: number)
     //  *  @param y : 定投部分 - 单笔投资钱
     //  *  @param x : 单笔投资钱
     //  *  @param f : r / 定投期
     //  *  @param n : 投资年限
     //  *  @param c : 投资频次 月、季度、年
     //  *  @param r : 组合收益率
     //  *  @param t : 通胀率
     const z = efn(this.planObj.investmentAmount, this.planObj.investPlanAutoTermAmount, this.historyEarnState.annualRateOfReturn / 100, this.planObj.durationYears, this.freqObj[this.planObj.investPlanAutoFreq])
     //  console.log('z', z)
     this.planObj.planEarnings = z
   }

   // 扣除通胀后未来收益：
   caclCtefn() {
     const m = ctefn(this.planObj.investmentAmount, this.planObj.investPlanAutoTermAmount, this.historyEarnState.annualRateOfReturn / 100, this.planObj.durationYears, this.freqObj[this.planObj.investPlanAutoFreq], this.planObj.assumeInflationRate / 100)
     //  console.log('m', m)
     this.planObj.planEarningsDeductInflation = m
   }

   get historyEarnState() {
     return PortMudule.historyEarn
   }

  @Watch('historyEarnState.annualRateOfReturn', { deep: true })
   public calcEarn() {
     this.calcEfn()
     this.caclCtefn()
     this.$nextTick(() => {
       this.setOption()
     })
   }

  private async getInvest() {
    const res = await getPlan({
      customerId: CommonMudule.customerId
    })
    if (res.statusCode === 0 && res.data) {
      if (res.data) {
        PortMudule.SET_PLANID(res.data.id)
        PortMudule.SET_RISKTYPE(res.data.riskType)
        res.data.investPlanAutoFreq = res.data.investPlanAutoFreq || 'Monthly'
        res.data.assumeInflationRate = res.data.assumeInflationRate || 2
        this.planObj = Object.assign({}, res.data)
        this.$nextTick(() => {
          this.setOption()
        })
      }
    } else {
      this.$message({
        type: 'error',
        message: res.errorMsg
      })
    }
  }

  private savePro() {
    (this.$refs.asForm as ElForm).validate(async valid => {
      if (valid) {
        const res = await savePlan(this.planObj)
        if (res.statusCode === 0 && res.data) {
          if (PortMudule.alone) {
            this.$router.push('/portfolio/aloneIndex')
          } else {
            this.$router.push('/portfolio/group2')
          }
          this.$message({
            type: 'success',
            message: '保存成功'
          })
        }
      }
    })
  }
}

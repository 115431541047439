







import { Component, Vue } from 'vue-property-decorator'
import { analysText } from '@/utils/pageBottomText'
import PageButtomText from '@/components/pageBottomText/index.vue'
import AnalyChart from './coms/analyChart.vue'

@Component({
  name: 'printFamily',
  components: {
    AnalyChart,
    PageButtomText
  }
})

export default class extends Vue {
  analysText = analysText
}

















































































































import echarts from 'echarts'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ChartRes } from '@/types/analy'
import { formatRes } from '@/utils/tools'
import { SeriesType } from '@/types/kyc'
import lineChart from '@/components/chart/lineChart.vue'
import { fetchNowAs, fetchNowChart, fetchSuggestChart } from '@/api/portfolio'
import { CommonMudule } from '@/store/modules/common'
import { PortMudule } from '@/store/modules/portfolio'
import pieChart from '@/components/chart/pieChart.vue'
import { barOption3 } from '@/store/modules/option'
import { cloneDeep } from 'lodash'

@Component({
  name: 'tableBar',
  components: {
    pieChart,
    lineChart
  }
})
export default class extends Vue {
  // @Prop({ required: true }) data!: ChartRes
  // @Prop({ required: false, default: 'pie' }) chartType!: string
  barOption = {}
  private asObj = {
    customerAssetAllocationTable: [],
    totalAssetAmount: 0,
    suggestAddUsd: 0,
    equivalentCny: 0
  }
    private seriesNow: SeriesType = {
      radius: [0, '55%'],
      type: 'pie',
      hoverAnimation: true,
      data: []
    }
   private seriesSuggest: SeriesType = {
     radius: [0, '55%'],
     type: 'pie',
     hoverAnimation: true,
     data: [

     ]
   }

  loading = true
  tbData: any[] = []
  thData: string[] = []

  created() {
    this.initFetch()
  }

  setBarOpt(tbData: any) {
    const tempOpt = cloneDeep(barOption3)
    const temp:any[] = []
    tbData.forEach((itemGraph: any, index: number) => {
      const a: any[] = []
      a[0] = itemGraph.typeName
      a.push(itemGraph.amountPercent)
      a.push(itemGraph.suggestPercent)
      temp.push(a)
    })
    tempOpt.dataset.source = temp
    tempOpt.yAxis.axisLabel.formatter = '{value}%'
    tempOpt.legend = {}
    this.barOption = tempOpt
    console.log('setBarOpt tempOpt', tempOpt)
  }

  async initFetch() {
    const p = {
      customerId: CommonMudule.customerId
    }
    const resArr = await Promise.all([fetchNowAs(p), fetchNowChart(p), fetchSuggestChart(p)])
    if (resArr[0].statusCode === 0) {
      this.$nextTick(() => {
        const temp = cloneDeep(resArr[0].data)
        temp.suggestAddUsd = Number(temp.suggestAddUsd.toFixed(2))
        temp.equivalentCny = temp.equivalentCny.toFixed(2)
        this.asObj = temp
        this.asObj.customerAssetAllocationTable = resArr[0].data.customerAssetAllocationTable
        console.log('总资产配置分析', resArr)
        this.setBarOpt(resArr[0].data.customerAssetAllocationTable)
      })
    }

    if (resArr[1].statusCode === 0) {
      const { categoryPercentages } = resArr[1].data
      this.seriesNow.data = formatRes(categoryPercentages).filter((v:any) => v.value)
    }

    if (resArr[2].statusCode === 0) {
      const { categoryPercentages } = resArr[2].data
      this.seriesSuggest.data = formatRes(categoryPercentages).filter((v:any) => v.value)
      console.log(' this.seriesSuggest', this.seriesSuggest)
      this.loading = false
    }
  }
}


































































































































import { Vue, Component } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import pieChart from '@/components/chart/pieChart.vue'
import { EarnHistoryModel } from '@/types/portfolio'
import { SeriesType } from '@/types/kyc'
import { formatRes } from '@/utils/tools'
import { fetchNowAs, fetchNowChart, fetchSuggestChart, fetchEarnList } from '@/api/portfolio'
import { CommonMudule } from '@/store/modules/common'
import { PortMudule } from '@/store/modules/portfolio'
import { riskTypeOjb3 } from '@/store/modules/option'
import { cloneDeep } from 'lodash'
import { comdifyKill } from '@/utils/filter'

@Component({
  name: 'notBlockOne',
  components: {
    moduleHeader,
    pieChart
  }
})
export default class extends Vue {
  comdifyKill = comdifyKill
  allEarnData = []
    selectItem = {
      type: ''
    }
    private asObj = {
      customerAssetAllocationTable: [],
      totalAssetAmount: 0,
      suggestAddUsd: 0,
      equivalentCny: 0
    }
    private seriesNow: SeriesType = {
      radius: [0, '60%'],
      type: 'pie',
      top: '8%',
      hoverAnimation: true,
      data: []
    }
   private seriesSuggest: SeriesType = {
     radius: [0, '60%'],
     type: 'pie',
     top: '8%',
     hoverAnimation: true,
     data: [

     ]
   }

   created() {
     this.initFetch()
   }

   reset() {
     this.$router.push('/portfolio/index')
   }

   get customerName() {
     return CommonMudule.customerName
   }

   async initFetch() {
     PortMudule.getHistoryEarnAction()
     const p = {
       customerId: CommonMudule.customerId
     }
     const resArr = await Promise.all([fetchNowAs(p), fetchNowChart(p), fetchSuggestChart(p), fetchEarnList()])
     if (resArr[0].statusCode === 0) {
       this.$nextTick(() => {
         const temp = cloneDeep(resArr[0].data)
         temp.suggestAddUsd = Number(temp.suggestAddUsd.toFixed(2))
         temp.equivalentCny = temp.equivalentCny.toFixed(2)
         this.asObj = temp
         this.asObj.customerAssetAllocationTable = resArr[0].data.customerAssetAllocationTable
       })
     }

     if (resArr[1].statusCode === 0) {
       const { categoryPercentages } = resArr[1].data
       this.seriesNow.data = formatRes(categoryPercentages).filter((v:any) => v.value)
     }

     if (resArr[2].statusCode === 0) {
       const { categoryPercentages } = resArr[2].data
       this.seriesSuggest.data = formatRes(categoryPercentages).filter((v:any) => v.value)
     }

     if (resArr[3].statusCode === 0 && resArr[3].data) {
       this.allEarnData = resArr[3].data
       // @ts-ignore
       this.selectItem = this.allEarnData.find((item: EarnHistoryModel) => item.riskType === PortMudule.riskType) || {}
       // @ts-ignore
       this.selectItem.type = riskTypeOjb3[this.selectItem.riskType]
     }
   }

   modify() {
     this.$router.push('/portfolio/portKYC/plan')
   }
}

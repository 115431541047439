





import { Vue, Component } from 'vue-property-decorator'
import { fetchArticleList } from '@/api/portfolio'
import { childData } from '../../../data'
import { CommonMudule } from '@/store/modules/common'

@Component({
  name: 'printTagSection'
})
export default class extends Vue {
  get picList() {
    return CommonMudule.imgSource.pdf_childedu || []
  }
}

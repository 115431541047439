







import { Vue, Component, Prop } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import planGroup from './plan.vue'
import { childfindInfo } from '@/api/child'
import { CommonMudule } from '@/store/modules/common'

@Component({
  name: 'portfolio',
  components: {
    moduleHeader,
    planGroup
  }
})
export default class extends Vue {
  @Prop() private onlyChild!: Boolean
  isPlan = true
  centerDialogVisible = true
  private isFirst:boolean = true
  resize() {
    // Handle resize
  }
}

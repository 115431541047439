















import { Component, Vue } from 'vue-property-decorator'
import { persionText } from '@/utils/pageBottomText'
import PageButtomText from '@/components/pageBottomText/index.vue'
import TagSection from './coms/tag.vue'
import PlanPage from './coms/planPage.vue'
import PlanDetailPage from './coms/planDetailPage.vue'

@Component({
  name: 'printPension',
  components: {
    TagSection,
    PlanPage,
    PlanDetailPage,
    PageButtomText
  }
})

export default class extends Vue {
   persionText = persionText
}

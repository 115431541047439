











































































import { Vue, Component } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import pieChart from '@/components/chart/pieChart.vue'
import { barOption2 } from '@/store/modules/option'
import lineChart from '@/components/chart/lineChart.vue'
import MultiFunctionLine from '@/views/portfolio/components/multiFunctionLine/index.vue'
import { SeriesType } from '@/types/kyc'
import variables from '@/styles/_variables.scss'
import { keepTwoDecimalFull } from '@/utils/filter'
import { formatRes } from '@/utils/tools'
import {
  fetchSuggestChart,
  fetchProfitTable,
  fetchCurrentChart,
  fetchNowChart,
  fetchEarningLine,
  fetchEarningBar,
  getPlan,
  timeInvest,
  backTest3
} from '@/api/portfolio'
import { cloneDeep } from 'lodash'
import { CommonMudule } from '@/store/modules/common'
import viewBom from './viewBom.vue'

@Component({
  name: 'notBlockOne',
  components: {
    moduleHeader,
    pieChart,
    lineChart,
    MultiFunctionLine,
    viewBom
  }
})
export default class extends Vue {
  keepTwoDecimalFull = keepTwoDecimalFull
  aloneTbData:any[] = []
  private isFirst: boolean = true;
  private alone: boolean = true;
  lineArr: any = []
  private profitData = {
    earningsForecast: [
      {
        name: '预计年化回报率',
        value: ''
      },
      {
        name: '预期年化波动',
        value: ''
      },
      {
        name: '最大回撤',
        value: ''
      },
      {
        name: '年收益率期间（90%置信度）',
        value: ''
      }
    ],
    suggestProspectiveEarnings: [
      {
        annualRateOfReturn: 0,
        createBy: '',
        createByName: '',
        createTime: '',
        deleted: true,
        earningsInterval: '',
        id: '',
        maxDrawback: 0,
        riskType: '',
        standardDeviation: 0,
        tenantId: ''
      }
    ]
  };
  private seriesNow: SeriesType = {
    gird: {
      top: '8%',
    },
    type: 'pie',
    hoverAnimation: true,
    data: []
  };
  private seriesSuggest: SeriesType = {
    gird: {
      top: '8%',
    },
    type: 'pie',
    hoverAnimation: true,
    data: []
  };

   private seriesTime: SeriesType = {
     gird: {
       top: '8%',
     },
     type: 'pie',
     hoverAnimation: true,
     data: []
   };

  private optionsEarnings = {}
  private optionsCurrent = {}

  getOptionsEarnings(data1:any, data2:any, data3:any, data4:any) {
    const optionsEarnings = {
      grid: {
        show: true,
        bottom: 55,
        left: 130,
        height: 180
      },
      color: [variables.chartColor],
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: data4,
          axisLabel: {
            interval: 0,
            onZero: true
          }
        }
      ],
      yAxis: {
        type: 'value'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      series: [
        {
          name: '较好情况',
          type: 'line',
          stack: '总量',
          areaStyle: {
            shadowColor: 'rgba(33,121,255, 0.5)',
            shadowBlur: 10,
            normal: {
              color: 'rgba(33,121,255, 0.5)' // 改变区域颜色
            }
          },
          data: data1
        },
        {
          name: '中值',
          type: 'line',
          stack: '总量',
          areaStyle: {
            shadowColor: 'rgba(33,121,255, 1)',
            shadowBlur: 10,
            normal: {
              color: 'rgba(33,121,255, 1)', // 改变区域颜色
            }
          },
          data: data2
        },
        {
          name: '较差情况',
          type: 'line',
          stack: '总量',
          areaStyle: {
            shadowColor: 'rgba(33,121,255, 0.3)',
            shadowBlur: 10,
            normal: {
              color: 'rgba(33,121,255, 0.3)', // 改变区域颜色
            }
          },
          data: data3
        },
      ]
    }
    return optionsEarnings
  }
  created() {
    this.initFetch()
  }
  mounted() {
    this.getQueryLine()
  }
  handleBackGroup2() {
    if (this.alone) {
      this.$router.push('/portfolio/aloneIndex')
    } else {
      this.$router.push({ path: '/portfolio/group2' })
    }
  }

  formatTableData(temp: any) {
    const data = []
    for (const key in temp) {
      if (key === 'earningsForecast') {
        temp['earningsForecast'].moduelCal = '产品综合预测法'
      }

      if (key === 'suggestProspectiveEarnings') {
        temp['suggestProspectiveEarnings'].moduelCal = '建议模型历史数据法'
      }
      data.push(temp[key])
    }
    return data
  }

  async initFetch() {
    const p = {
      customerId: CommonMudule.customerId
    }
    const resArr = await Promise.all([
      fetchSuggestChart(p),
      fetchCurrentChart(p),
      timeInvest(p),
      fetchProfitTable(p),
      getPlan(p)
    ])
    // const resArr = await Promise.all([fetchSuggestChart(p), fetchCurrentChart(p), fetchNowChart(p)])

    if (resArr[0].statusCode === 0 && resArr[0].data) {
      const { categoryPercentages } = resArr[0].data
      this.seriesSuggest.data = formatRes(categoryPercentages)
    }

    if (resArr[2].statusCode === 0 && resArr[2].data) {
      const { categoryPercentages } = resArr[2].data
      this.seriesTime.data = formatRes(categoryPercentages).filter((item: any) => item.value)
    }

    if (resArr[3].statusCode === 0 && resArr[3].data) {
      this.$nextTick(() => {
        const temp = cloneDeep(resArr[3].data)
        const data = this.formatTableData(temp)
        console.log('建议配置模型历史收益测算 data', data)
        this.aloneTbData = data
      })
    }

    if (resArr[4].statusCode === 0) {
      this.alone = resArr[4].data.alone
    }
  }
  /**
   * 获取折线图数据
   * getQueryLine
   */
  async getQueryLine() {
    const res = await backTest3({ years: 0, customerId: CommonMudule.customerId })
    if (res.statusCode === 0 && res.data) {
      const _data = []
      for (let i = 0; i < res.data.length; i++) {
        const resdata = res.data[i]
        const { date, value } = resdata
        _data.push([date, value])
      }
      this.lineArr = _data
    } else {
      this.lineArr = []
    }
  }
}


























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import SplitPane from 'vue-splitpane'
import moduleHeader from '@/components/moduleHeader/index.vue'
import lineChart from '@/components/chart/lineChart.vue'
import { fetchPensionFind, savePension } from '@/api/pension'
import { getFinancialAnalysisInfo } from '@/api/articles'
import { PensionPlanData } from '@/types/pension'
import { cloneDeep } from 'lodash'
import { CommonMudule } from '@/store/modules/common'
import variables from '@/styles/_variables.scss'
import { delcommafy, comdify } from '@/utils/validate'

@Component({
  name: 'printPensionPlan',
  components: {
    SplitPane,
    moduleHeader,
    lineChart
  }
})

export default class extends Vue {
  private hoverBoxShow:boolean = false
  joinCastSurely:boolean = false
  memberName = ''
  isPlan = false
  isLoading = true
  inAmount = 0
  outAmount = 0
  inAmountStr = ''
  outAmountStr = ''
  private pensionPlan: PensionPlanData = {
    age: 56, // 当前年龄
    assumeInflationRate: 2.5, // 通胀率
    automaticInvestment: true,
    createBy: '',
    createByName: '',
    createTime: '',
    currentAnnuityAmount: 0, // 现有年金储备
    currentExpendPerYear: 0, // 当前每年支出
    currentYearAmount: 0, // 预计退休首年需要支出
    customerId: CommonMudule.customerId,
    deathAge: 90,
    pensionExpendPercentage: 100, // 预计支出为当前的百分比
    pensionTerm: 30, // 预计养老退休年金领取年限
    planEarnings: 3.5, // 预计养老年金收益率
    retireAge: 60, // 预计退休年龄
    suggestAddAnnuityAmount: 0, // 建议补充最小年金金额
    tenantId: '',
    totalAmount: 0, // 养老所需总金额
    totalExpends: 0,
    totalIncome: 0
  }
  pensionPlanStr = {
    currentAnnuityAmount: '',
    currentExpendPerYear: '',
    currentYearAmount: '',
    suggestAddAnnuityAmount: '',
    totalAmount: ''
  }
  barOption = {
    grid: {
      show: true,
      bottom: 55,
      left: 90,
    },
    color: [variables.chartColor],
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: [] as Array<number>
      }
    ],
    yAxis: {
      type: 'value'
    },
    tooltip: {
    },
    series: [{
      type: 'line',
      data: [] as Array<number>

    }]
  }
  ageData = [] as Array<number>
  ageNumData = [] as Array<number>
  created() {
    this.initFetch()
  }
  @Watch('pensionPlan.retireAge', { immediate: false, deep: false })
  retireAge(newVal: any) {
    this.pensionPlan.deathAge = this.accAdd(Number(newVal || 0), Number(this.pensionPlan.pensionTerm || 0))
    this.countData()
  }
  @Watch('pensionPlan.pensionTerm', { immediate: false, deep: false })
  pensionTerm(newVal: any) {
    this.pensionPlan.deathAge = this.accAdd(Number(this.pensionPlan.retireAge), Number(newVal || 0))
    this.countData()
  }
  @Watch('pensionPlan.pensionExpendPercentage', { immediate: false, deep: false })
  pensionExpendPercentage() {
    this.countData()
  }
  @Watch('pensionPlan.assumeInflationRate', { immediate: false, deep: false })
  assumeInflationRate() {
    this.countData()
  }
  @Watch('pensionPlan.planEarnings', { immediate: false, deep: false })
  planEarnings() {
    this.countData()
  }
  resize() {
    // Handle resize
  }
  accAdd(arg1: number, arg2: number) { // 加法
    let r1
    let r2
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    const m = Math.pow(10, Math.max(r1, r2))
    return (arg1 * m + arg2 * m) / m
  }
  accSub(arg1: number, arg2: number) { // 减法
    let r1
    let r2
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    const m = Math.pow(10, Math.max(r1, r2))
    const n = (r1 >= r2) ? r1 : r2
    return Number(((arg1 * m - arg2 * m) / m).toFixed(n))
  }
  accDiv(arg1: number, arg2: number) { // 乘法
    let c = 0
    const d = arg1.toString()
    const e = arg2.toString()
    try {
      c += d.split('.')[1].length
    } catch (f) {
      // s
    }
    try {
      c += e.split('.')[1].length
    } catch (f) {
      // s
    }
    return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c)
  }
  accMul(arg1: number, arg2: number) { // 除法
    let t1 = 0
    let t2 = 0
    try {
      t1 = arg1.toString().split('.')[1].length
    } catch (e) {
      // s
    }
    try {
      t2 = arg2.toString().split('.')[1].length
    } catch (e) {
      // s
    }
    const r1 = Number(arg1.toString().replace('.', ''))
    const r2 = Number(arg2.toString().replace('.', ''))
    return (r1 / r2) * Math.pow(10, t2 - t1)
  }
  pow(num: number, n: number) {
    let sum = 1
    for (let i = 0; i < n; i++) {
      sum = this.accDiv(sum, num)
    }
    return sum
  }
  backToEdit() {
    this.$router.push({
      path: '/pension/index',
      query: {
        type: 'edit'
      }
    })
  }
  async initData() {
    const res = await getFinancialAnalysisInfo({ customerId: CommonMudule.customerId })
    if (res.success && res.statusCode === 0) {
      if (res.data.customer.age > 59) {
        this.isPlan = false
      } else {
        this.inAmount = res.data.customerMember.inAmount
        this.outAmount = res.data.customerMember.outAmount
        this.inAmountStr = comdify((res.data.customerMember.inAmount || 0).toString())
        this.outAmountStr = comdify((res.data.customerMember.outAmount || 0).toString())
        this.pensionPlan.age = res.data.customer.age
        this.pensionPlan.currentExpendPerYear = res.data.customerMember.outAmount
        this.pensionPlanStr.currentExpendPerYear = comdify((res.data.customerMember.outAmount || 0).toString())
        this.memberName = res.data.customerMember.memberName
        this.countData()
        this.isPlan = true
      }
      this.isLoading = false
    } else {
      this.$message.error(res.errorMsg)
    }
  }
  initChart() {
    this.barOption.xAxis[0].data = cloneDeep(this.ageData)
    this.barOption.series[0].data = cloneDeep(this.ageNumData)
  }
  countData() {
    this.pensionPlan.deathAge = this.accAdd(Number(this.pensionPlan.retireAge || 0), Number(this.pensionPlan.pensionTerm || 0))
    this.pensionPlan.currentYearAmount = this.getCurrentYearAmount()
    this.pensionPlan.totalAmount = this.getTotalAmount()
    this.pensionPlan.suggestAddAnnuityAmount = this.getSuggestAddAnnuityAmount()
    this.pensionPlanStr.currentYearAmount = comdify((this.pensionPlan.currentYearAmount || 0).toString())
    this.pensionPlanStr.totalAmount = comdify((this.pensionPlan.totalAmount || 0).toString())
    this.pensionPlanStr.suggestAddAnnuityAmount = comdify((this.pensionPlan.suggestAddAnnuityAmount || 0).toString())
    this.initChart()
  }
  totalAmountUnderCircle(num: number, min: number, max: number, sum: number): number {
    const numEnd = this.accDiv(num, (this.accAdd(1, this.accMul((Number(this.pensionPlan.assumeInflationRate) || 0), 100))))
    this.ageData.push(min)
    this.ageNumData.push(Math.round(numEnd))
    sum = this.accAdd(sum, numEnd)
    if (min === max) {
      return sum
    } else {
      return this.totalAmountUnderCircle(numEnd, this.accAdd(min, 1), max, sum)
    }
  }
  // 退休当年所需金额
  getCurrentYearAmount() {
    const pre = this.accDiv(Number(this.pensionPlan.currentExpendPerYear), this.accMul(Number(this.pensionPlan.pensionExpendPercentage), 100))
    const under = this.accAdd(1, this.accMul((Number(this.pensionPlan.assumeInflationRate) || 0), 100))
    const n = this.accSub((Number(this.pensionPlan.retireAge) || 0), (Number(this.pensionPlan.age) || 0))
    const yearAmount = Math.round(this.accDiv(pre, this.pow(under, n)))
    return yearAmount >= 0 ? yearAmount : 0
  }
  // 养老所需总金额
  getTotalAmount() { // pensionTerm
    const num = Number(this.pensionPlan.currentYearAmount) || 0
    const min = this.accAdd((Number(this.pensionPlan.retireAge) || 0), 1)
    const max = this.accAdd((Number(this.pensionPlan.retireAge) || 0), (Number(this.pensionPlan.pensionTerm) || 0))
    this.ageData = [Number(this.pensionPlan.retireAge)]
    this.ageNumData = [num]
    const totalAmount = min <= max ? this.totalAmountUnderCircle(num, min, max, num) : 0
    return totalAmount >= 0 ? Math.round(totalAmount) : 0
  }
  // 补充最小年金金额
  getSuggestAddAnnuityAmount() {
    const up = this.accDiv((Number(this.pensionPlan.currentExpendPerYear) || 0), this.accMul((Number(this.pensionPlan.pensionExpendPercentage) || 0), 100))
    const upRight = this.accAdd(1, this.accMul((Number(this.pensionPlan.assumeInflationRate) || 0), 100))
    const under = this.accAdd(1, this.accMul((Number(this.pensionPlan.planEarnings) || 0), 100))
    const n = this.accAdd(this.accSub((Number(this.pensionPlan.retireAge) || 0), (Number(this.pensionPlan.age) || 0)), (Number(this.pensionPlan.pensionTerm) || 0))
    const i = this.accSub((Number(this.pensionPlan.retireAge) || 0), (Number(this.pensionPlan.age) || 0))
    let suggestAddAmount = 0
    for (let k = i; k <= n; k++) {
      suggestAddAmount += this.getSuggestAddAnnuityAmountItem(up, upRight, under, k)
    }
    return suggestAddAmount >= 0 ? Math.round(suggestAddAmount) : 0
  }
  getSuggestAddAnnuityAmountItem(up: number, upRight: number, under: number, k: number) {
    return this.accMul(this.accDiv(up, this.pow(upRight, k)), this.pow(under, k))
  }
  async jumpToDetail() {
    const data = cloneDeep(this.pensionPlan)
    const res = await savePension(data)
    if (res.success && res.statusCode === 0) {
      this.$router.push('/pension/planDetail')
    } else {
      this.$message.error(res.errorMsg)
    }
  }
  async initFetch() {
    this.isLoading = true
    const res = await fetchPensionFind({
      customerId: CommonMudule.customerId
    })
    if (res.success && res.statusCode === 0) {
      if (res.data) {
        for (const key in res.data) {
          const value = res.data[key]
          if (value !== null && value !== undefined && value !== '') {
            this.$set(this.pensionPlan, key, value)
            if (key === 'currentAnnuityAmount') {
              this.pensionPlanStr.currentAnnuityAmount = comdify((value || 0).toString())
            }
          }
        }
      }
      this.initData()
    } else {
      this.$message.error(res.errorMsg)
    }
  }
}

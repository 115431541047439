


































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import pieChart from '@/components/chart/pieChart.vue'
import { SeriesType } from '@/types/kyc'
import { RiskType, EarnHistoryModel } from '@/types/portfolio'
import { formatRes } from '@/utils/tools'
import { fetchEarnList, fetchRiskType, riskTypeSave } from '@/api/portfolio'
import { CommonMudule } from '@/store/modules/common'
import { PortMudule } from '@/store/modules/portfolio'
import { cloneDeep } from 'lodash'
import { riskTypeOjb3 } from '@/store/modules/option'

@Component({
  name: 'notBlockOne',
  components: {
    moduleHeader,
    pieChart
  }
})
export default class extends Vue {
    selectItem = {
      type: '',
      earningsInterval: 0,
      annualRateOfReturn: 0
    }
    allEarnData = []
    private asObj = {
      customerAssetAllocationTable: [],
      totalAssetAmount: 0,
      suggestAddUsd: 0,
      equivalentCny: 0
    }
    private seriesType: SeriesType = {
      radius: [0, '55%'],
      type: 'pie',
      hoverAnimation: true,
      data: []
    }
    earnData = [
      {
        type: '历史组合收益',
        Conservative: '',
        ConservativeModerately: '',
        Steady: '',
        PositiveModerately: '',
        Positive: ''
      },
      {
        type: '组合历史最大回撤',
        Conservative: '',
        ConservativeModerately: '',
        Steady: '',
        PositiveModerately: '',
        Positive: ''
      },
    ]
   showChartType = false

   created() {
     this.initFetch()
   }

   get customerName() {
     return CommonMudule.customerName
   }

   get riskType() {
     return PortMudule.riskType
   }

   @Watch('riskType', { immediate: true })
   private caclRisk(v: RiskType) {
     if (v) {
       this.selectType(v)
     }
   }

   async change(riskType: RiskType) {
     const p = {
       customerId: CommonMudule.customerId,
       riskType: riskType
     }
     const res = await riskTypeSave(p)
     if (res.success) {
       //  this.$message({
       //    type: 'success',
       //    message: '更新成功'
       //  })
     }
   }

   async selectType(type: RiskType) {
     // @ts-ignore
     this.selectItem = this.allEarnData.find((item: EarnHistoryModel) => item.riskType === type) || {}
     // @ts-ignore
     this.selectItem.type = riskTypeOjb3[this.selectItem.riskType]
     PortMudule.SET_CACL_EARN(this.selectItem.annualRateOfReturn)
     PortMudule.SET_RISKTYPE(type)
     const res = await fetchRiskType({
       riskType: type
     })
     if (res.statusCode === 0 && res.data) {
       this.change(type)
       this.showChartType = true
       this.seriesType.data = formatRes(res.data, 'productTypeName', 'suggestPercentage').filter((v:any) => v.value)
     }
   }

   async initFetch() {
     const res2 = await fetchEarnList()
     if (res2.statusCode === 0) {
       const typeObj = {
         type: '历史组合收益',
         Conservative: '',
         ConservativeModerately: '',
         Steady: '',
         PositiveModerately: '',
         Positive: ''
       }
       console.log('res2', res2)
       this.allEarnData = res2.data
       if (PortMudule.riskType) {
         // @ts-ignore
         this.selectItem = this.allEarnData.find((item: EarnHistoryModel) => item.riskType === PortMudule.riskType) || {}
         // @ts-ignore
         this.selectItem.type = riskTypeOjb3[this.selectItem.riskType]
         PortMudule.SET_CACL_EARN(this.selectItem.annualRateOfReturn)
       }
       for (const val of res2.data) {
         for (const key in typeObj) {
           if (val.riskType === key) {
             (this.earnData[0] as any)[key] = val.annualRateOfReturn;
             (this.earnData[1] as any)[key] = val.maxDrawback
           }
         }
       }
     }
   }

   adjust() {
     this.showChartType = false
   }
}











































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import tablePie from './charts/tablePie.vue'
import tableScatter from './charts/tableScatter.vue'
import { AnalyModule } from '@/store/modules/analy'
import AnalyMixins from '@/utils/mixins/analy-mixins'
import { mixins } from 'vue-class-component'
import tableBar from './charts/tableBar.vue'
import tableText from './charts/tableText.vue'
import tableTextPie from './charts/tableTextPie.vue'

@Component({
  name: 'printAnalyChart',
  components: {
    moduleHeader,
    tablePie,
    tableBar,
    tableText,
    tableTextPie,
    tableScatter
  }
})
export default class extends mixins(AnalyMixins) {
  created() {
    Promise.all([
      this.getTotalAs(),
      this.getAnalyListAction(),
      this.getAnalyListActionOther(),
      this.getChildInfoAction(),
      this.getFamalySecuritObj(),
      this.getFamalyConfig(),
      this.getChildIllData()
    ])
  }
  activities = [
    {
      content: '家庭资产总览',
      color: '#2179FF',
      type: 'primary',
      index: 0
    },
    {
      content: '财务指标分析',
      type: 'primary',
      index: 1,
      color: ''
    },
    {
      content: '资产配置分析',
      type: 'primary',
      index: 2,
      color: ''
    },
    {
      content: '家庭保障分析',
      type: 'primary',
      index: 3,
      color: ''
    }
  ]
  get totalAs() {
    return AnalyModule.totalAs
  }

  get analyObj() {
    return AnalyModule.analyObj
  }

  get analyObj2() {
    return AnalyModule.analyObj2
  }

  get childTeachObjArr() {
    return AnalyModule.childTeachObjArr
  }

  get famalySecuritObj() {
    return AnalyModule.famalySecuritObj
  }

  get childIllObjArr() {
    return AnalyModule.childIllObjArr
  }

  get asConfig() {
    return AnalyModule.asConfig
  }

  onBack() {
    this.$router.push({
      path: '/portfolio/portKYC/analy'
    })
  }
  goAchor(selector: any, activity: any) {
    this.activities[activity.index].color = '#2179FF'
    this.$el.querySelector(selector).scrollIntoView()
    this.activities.map((item) => {
      if (item.index !== activity.index) {
        item.color = ''
      }
    })
  }
}

import request from '@/utils/request'
import {
  PensionFindParam,
  fetchInsuranceParam,
  saveInsuranceParam,
  pensionInsuranceStatisticsParam
} from '@/types/pension'

/**
 * 养老页面初始化查询
 */
export const fetchPensionFind = (params: PensionFindParam) =>
  request({
    url: '/blackcat/pension/find',
    method: 'get',
    loading: false,
    params
  })

/**
 * 保存养老规划初始信息
 */
export const savePension = (data: any) =>
  request({
    url: '/blackcat/pension/save',
    method: 'post',
    data,
    headers: {
      contentType: 'application/json'
    }
  })

/**
 * 养老规划信息统计
 */
export const fetchPensionSummary = (params: PensionFindParam) =>
  request({
    url: '/blackcat/pension/summary',
    method: 'get',
    loading: false,
    params
  })

// 查询保险列表
export const pensionInsuranceList = (params: fetchInsuranceParam) =>
  request({
    url: '/blackcat/insurance/list',
    method: 'get',
    loading: false,
    params
  })

// 保存保险产品
export const pensionInsuranceSave = (params: saveInsuranceParam) =>
  request({
    url: '/blackcat/insurance/transaction/save',
    method: 'POST',
    data: params
  })

// 查询保险统计
export const pensionInsuranceStatistics = (params: PensionFindParam) =>
  request({
    url: '/blackcat/pension/insurance/statistics',
    method: 'get',
    loading: false,
    params
  })

// 年缴保费信息表
export const pensionInsuranceInfoList = (params: PensionFindParam) =>
  request({
    url: '/blackcat/pension/insurance/info/list',
    method: 'get',
    loading: false,
    params
  })

// 保险购买信息查询
export const pensionTransaction = (params: PensionFindParam) =>
  request({
    url: '/blackcat/pension/transaction',
    method: 'get',
    loading: false,
    params
  })

/**
*  现有资产产品列表
*/
export const fetchPensionProductList = (params: any) =>
  request({
    url: '/blackcat/product/page',
    method: 'get',
    loading: false,
    params
  })

/**
*  获取定投信息
*/
export const fetchPensionInvestmentPlan = (params: any) =>
  request({
    url: '/blackcat/pension/investment/plan/auto',
    method: 'get',
    loading: false,
    params
  })

/**
*  保存定投信息
*/
export const pensionInvestmentSave = (params: any) =>
  request({
    url: '/blackcat/investment/plan/auto/save',
    method: 'POST',
    data: params
  })

/**
*  定投折线图
*/
export const pensionPlanChart = (params: any) =>
  request({
    url: '/blackcat/pension/investment/plan/auto/chart',
    method: 'get',
    loading: false,
    params
  })

/**
*  删除保险信息
*/
export const pensionInvestmentDelete = (params: PensionFindParam) =>
  request({
    url: '/blackcat/pension/delete',
    method: 'POST',
    params
  })













import { Component, Vue, Prop } from 'vue-property-decorator'
import { childText } from '@/utils/pageBottomText'
import PageButtomText from '@/components/pageBottomText/index.vue'
import TagSection from './coms/tag.vue'
import PageSection from './coms/three/index.vue'
import OtherSection from './coms/three/others.vue'

@Component({
  name: 'printChild',
  components: {
    TagSection,
    PageSection,
    OtherSection,
    PageButtomText
  }
})

export default class extends Vue {
  @Prop() private onlyChild!: Boolean
  childText = childText
}

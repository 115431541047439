








































































































import { Vue, Component } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import pieChart from '@/components/chart/pieChart.vue'
import lineChart from '@/components/chart/lineChart.vue'
import { SeriesType } from '@/types/kyc'
import variables from '@/styles/_variables.scss'
import { formatRes } from '@/utils/tools'
import viewBom from './viewBom.vue'
import {
  fetchSuggestChart,
  fetchProfitTable,
  fetchCurrentChart,
  fetchNowChart,
  fetchEarningLine,
  fetchEarningBar,
  getPlan,
  timeInvest,
  backTest3
} from '@/api/portfolio'
import { cloneDeep } from 'lodash'
import { CommonMudule } from '@/store/modules/common'
import { barOption2 } from '@/store/modules/option'
import { keepTwoDecimalFull } from '@/utils/filter'
import MultiFunctionLine from '@/views/portfolio/components/multiFunctionLine/index.vue'
@Component({
  name: 'notBlockOne',
  components: {
    moduleHeader,
    pieChart,
    lineChart,
    MultiFunctionLine,
    viewBom
  }
})
export default class extends Vue {
  keepTwoDecimalFull = keepTwoDecimalFull
  private isFirst: boolean = true;
  private alone: boolean = true;
  lineArr:any = []
  private profitData = {
    earningsForecast: [
      {
        name: '预计年化回报率',
        value: ''
      },
      {
        name: '预期年化波动',
        value: ''
      },
      {
        name: '最大回撤',
        value: ''
      },
      {
        name: '年收益率期间（90%置信度）',
        value: ''
      }
    ],
    suggestProspectiveEarnings: [
      {
        annualRateOfReturn: 0,
        createBy: '',
        createByName: '',
        createTime: '',
        deleted: true,
        earningsInterval: '',
        id: '',
        maxDrawback: 0,
        riskType: '',
        standardDeviation: 0,
        tenantId: ''
      }
    ]
  };
  private seriesNow: SeriesType = {
    radius: [0, '65%'],
    grid: {
      show: true,
      bottom: 55,
      left: 180,
      right: 180,
      height: 180
    },
    type: 'pie',
    hoverAnimation: true,
    data: []
  };

  private seriesTime: SeriesType = {
    radius: [0, '65%'],
    grid: {
      show: true,
      bottom: 55,
      right: 180,
      left: 180,
      height: 180
    },
    type: 'pie',
    hoverAnimation: true,
    data: []
  };
  private seriesSuggest: SeriesType = {
    radius: [0, '65%'],
    grid: {
      show: true,
      bottom: 55,
      right: 180,
      left: 180,
      height: 180
    },
    type: 'pie',
    hoverAnimation: true,
    data: []
  };
   private seriesAdjust: SeriesType = {
     radius: [0, '65%'],
     grid: {
       show: true,
       bottom: 55,
       right: 180,
       left: 180,
       height: 180
     },
     type: 'pie',
     hoverAnimation: true,
     data: []
   };

  private optionsCurrent = {}
  getCurrent(data1: any, data2:any) {
    console.log('data1', data1)
    console.log('data2', data2)
    const option = cloneDeep(barOption2)
    // return optionsCurrent
    return option
  }

  created() {
    this.initFetch()
  }

  handleBackGroup2() {
    if (this.alone) {
      this.$router.push('/portfolio/aloneIndex')
    } else {
      this.$router.push({ path: '/portfolio/group2' })
    }
  }

  formatTableData(temp: any) {
    temp.suggestProspectiveEarnings.moduelCal = '建议模型历史数据法'
    const data = {
      earningsForecast: [
        {
          name: '预计年化回报率',
          value: this.keepTwoDecimalFull(temp.earningsForecast.annualRateOfReturn) + '%'
        },
        {
          name: '预期年化波动',
          value: this.keepTwoDecimalFull(temp.earningsForecast.standardDeviation) + '%'
        },
        {
          name: '最大回撤',
          value: this.keepTwoDecimalFull(temp.earningsForecast.maxDrawback) + '%'
        },
        {
          name: '年收益率期间（90%置信度）',
          value: temp.earningsForecast.earningsInterval
        }
      ],
      suggestProspectiveEarnings: [temp.suggestProspectiveEarnings]
    }

    return data
  }

  async initFetch() {
    const p = {
      customerId: CommonMudule.customerId
    }
    const resArr = await Promise.all([
      fetchSuggestChart(p),
      fetchCurrentChart(p),
      fetchNowChart(p),
      fetchProfitTable(p),
      getPlan(p),
      timeInvest(p)
    ])
    if (resArr[0].statusCode === 0 && resArr[0].data) {
      const { categoryPercentages } = resArr[0].data
      this.seriesNow.data = formatRes(categoryPercentages).filter((item:any) => item.value)
    }

    if (resArr[1].statusCode === 0 && resArr[1].data) {
      const { categoryPercentages } = resArr[1].data
      this.seriesAdjust.data = formatRes(categoryPercentages).filter((item:any) => item.value)
    }

    if (resArr[2].statusCode === 0 && resArr[2].data) {
      const { categoryPercentages } = resArr[2].data
      this.seriesSuggest.data = formatRes(categoryPercentages).filter((item:any) => item.value)
    }

    if (resArr[3].statusCode === 0 && resArr[3].data) {
      this.$nextTick(() => {
        const temp = cloneDeep(resArr[3].data)
        console.log('this.formatTableData(temp)', this.formatTableData(temp))
        const data = this.formatTableData(temp)
        this.profitData = data
      })
    }

    if (resArr[4].statusCode === 0 && resArr[4].data) {
      this.alone = resArr[4].data.alone
    }

    if (resArr[5].statusCode === 0 && resArr[5].data) {
      const { categoryPercentages } = resArr[5].data
      this.seriesTime.data = formatRes(categoryPercentages).filter((item:any) => item.value)
    }
    console.log('resArr---', resArr)
  }
  mounted() {
    this.getQueryLine()
  }
  /**
   * 获取折线图数据
   * getQueryLine
   */
  async getQueryLine() {
    const res = await backTest3({ years: 0, customerId: CommonMudule.customerId })
    if (res.statusCode === 0 && res.data) {
      const _data = []
      for (let i = 0; i < res.data.length; i++) {
        const resdata = res.data[i]
        const { date, value } = resdata
        _data.push([date, value])
      }
      this.lineArr = _data
    } else {
      this.lineArr = []
    }
  }
}

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import scatterChart from '@/components/chart/scatterChart.vue'
import { ChartRes } from '@/types/analy'

@Component({
  name: 'tableText',
  components: {
    scatterChart
  }
})
export default class extends Vue {
  @Prop({ required: true }) data!: ChartRes
  @Prop({ required: false, }) keyName!: string
  loading = true

  @Watch('data', { immediate: true })
  getPropData(v: ChartRes) {
    if (v.id) {
      this.loading = false
    }
  }
}



















































































































import { Component, Vue } from 'vue-property-decorator'
import FamilySection from './template/family/index.vue' // 财富体检
import InvestSection from './template/invest/index.vue' // 投资组合规划
import BaseSection from './template/base/index.vue' // 家庭基础保障规划
import PensionSection from './template/pension/index.vue' // 养老规划
import ChildSection from './template/child/index.vue' // 子女教育规划
import { CommonMudule } from '@/store/modules/common'
import { cloneDeep } from 'lodash'

@Component({
  name: 'print',
  components: {
    FamilySection,
    InvestSection,
    BaseSection,
    PensionSection,
    ChildSection
  }
})
export default class extends Vue {
  showDialog = true
  onlyChild = false
  listData = {
    family: false,
    invest: false,
    base: false,
    pension: false,
    child: false
  }
  times = 8
  timer = null
  isLoading = true

  get picList() {
    return CommonMudule.imgSource.pdf_cover || []
  }

  showView(type: string) {
    if (CommonMudule.authArr.includes(type)) {
      return false
    }
    return true
  }

  closeDialog() {
    this.showDialog = false
  }
  showDialogEvent() {
    this.showDialog = true
  }
  downPDF() {
    this.showDialog = false
    const doc = document.querySelectorAll('.print-container img')
    if (doc.length > 0) {
      doc.forEach((item) => {
        item.setAttribute('style', 'height:1387px!important;')
      })
    }
    this.$nextTick(() => {
      this.addSplite()
      setTimeout(() => {
        this.showDialog = true
        window.print()
      }, 500)
    })
  }

  addSplite() {
    const flagEles: NodeListOf<HTMLDivElement> =
      document.querySelectorAll('.page-splite-flag')
    const flagArr = Array.from(flagEles)

    const tempArr = flagArr.reduce(
      (
        prve: any[],
        current: HTMLDivElement,
        index: number,
        arr: HTMLDivElement[]
      ) => {
        const offsetHeight = flagEles[index].offsetHeight
        if (prve) {
          prve.push({
            key: index,
            top: flagEles[index].offsetTop,
            instance: flagEles[index].offsetTop - flagEles[0].offsetTop,
            offsetHeight
          })
        }
        return prve
      },
      []
    )
    const t = tempArr.filter((item) => item.offsetHeight)
    // const t = tempArr
    let page = 1
    let addPageHeight = 0
    const pageHight = 1250
    const pageNumArr = t.map((item, index) => {
      let isAdd = false
      if (index === 0) {
        isAdd = true
      } else {
        if (t[index + 1]) {
          if (addPageHeight + t[index + 1].offsetHeight > pageHight) {
            page++
            addPageHeight = t[index].offsetHeight
            isAdd = true
          } else {
            addPageHeight = addPageHeight + t[index].offsetHeight
            isAdd = false
          }
        } else {
          const h = t[t.length - 1].offsetHeight
          if (addPageHeight + h > pageHight) {
            page++
            addPageHeight = 0
            isAdd = true
          } else {
            addPageHeight += h
            isAdd = false
          }
        }
      }

      item.addPageHeight = addPageHeight
      item.page = page
      item.isAdd = isAdd
      return item
    })
    console.log('pageNumArr', pageNumArr)
    pageNumArr.forEach((item) => {
      if (item.isAdd) {
        const ele =
          document.getElementsByClassName('page-splite-flag')[item.key]
        ele.setAttribute('style', 'page-break-before:always')
      }
    })
  }
  mounted() {
    document.title = '建议书'
    const keys = this.$route.query.keys || ''
    const keyList = (keys as string).split(',')
    if (keyList.length === 2 || keyList.length === 3) {
      this.times = 10
    }
    if (keys === 'child') {
      this.onlyChild = true
    }
    if (keyList.length === 4 || keyList.length === 5) {
      this.times = 15
    }
    if (keys) {
      keyList.forEach((item: string) => {
        this.$set(this.listData, item, true)
      })
    }
    ;(this as any).timer = setInterval(() => {
      this.times--
      if (this.times === 0) {
        clearInterval((this as any).timer)
      }
    }, 1000)
  }
}













import { Component, Vue } from 'vue-property-decorator'
import { familyBaseText } from '@/utils/pageBottomText'
import PageButtomText from '@/components/pageBottomText/index.vue'
import TagSection from './coms/tag.vue'
import BaseSection from './coms/base.vue'
import BaseDetailSection from './coms/basePlanDetail.vue'
import RuleDetail from './coms/rule.vue'

@Component({
  name: 'printBase',
  components: {
    TagSection,
    BaseSection,
    BaseDetailSection,
    PageButtomText
  }
})

export default class extends Vue {
  familyBaseText = familyBaseText
}

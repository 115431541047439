














import { Vue, Component } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import { PortMudule } from '@/store/modules/portfolio'
import { CommonMudule } from '@/store/modules/common'
import asGroup from './groupOne/asGroup.vue'
import aloneAsGroup from './groupOne/aloneAsGroup.vue'
import dialogcom from '@/components/dialog/index.vue'
import setPlan from './groupOne/setPlan.vue'
import SplitPane from 'vue-splitpane'
import { deletePlan, getPlan } from '@/api/portfolio'

@Component({
  name: 'portfolio',
  components: {
    moduleHeader,
    SplitPane,
    aloneAsGroup,
    asGroup,
    setPlan,
    dialogcom
  }
})
export default class extends Vue {
  alone = '0' // '1' 单独 2非单独
  showDialog = false
  private isFirst:boolean = true
  resize() {
    // Handle resize
  }

  reset() {
    this.showDialog = true
  }

  created() {
    this.initFetch()
  }

  initFetch() {
    this.getInvest()
    CommonMudule.getRMBRateAction()
  }

  private async getInvest() {
    const res = await getPlan({
      customerId: CommonMudule.customerId
    })
    if (res.statusCode === 0 && res.data) {
      if (res.data) {
        PortMudule.SET_ALONE(res.data.alone)
        PortMudule.SET_PLANID(res.data.id)
        PortMudule.SET_RISKTYPE(res.data.riskType)
        if (res.data.alone) {
          this.alone = '1'
        } else {
          this.alone = '2'
        }
      } else {
        // this.showDialog = true
      }
    } else {
      this.$message({
        type: 'error',
        message: res.errorMsg
      })
    }
  }

  async onLeftFun() {
    const res = await deletePlan(`investmentPlanId=${PortMudule.investmentPlanId}`)
    console.log('deletePlan', res)
    if (res.statusCode === 0 && res.data) {
      this.$message({
        type: 'success',
        message: '操作成功'
      })
      this.showDialog = false
      this.$router.push('/portfolio/index')
      PortMudule.SET_PLANID('')
    } else {
      this.$message({
        type: 'error',
        message: res.errorMsg
      })
    }
  }

  onRightFun() {
    this.showDialog = false
  }
}

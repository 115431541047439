











import { Component, Vue } from 'vue-property-decorator'
import { investText } from '@/utils/pageBottomText'
import PageButtomText from '@/components/pageBottomText/index.vue'
import TagSection from './coms/tag.vue'
import PageSection from './coms/index.vue'
import ThreeSection from './coms/groupThree/index.vue'

@Component({
  name: 'printInvest',
  components: {
    TagSection,
    PageSection,
    ThreeSection,
    PageButtomText
  }
})

export default class extends Vue {
  investText = investText
}


























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ChartRes } from '@/types/analy'
import { formatTbData } from '@/utils/tools'
import echarts from 'echarts'

@Component({
  name: 'tableBar',
})
export default class extends Vue {
  @Prop({ required: true }) data!: ChartRes

  loading = true
  tbData: any[] = []
  thData: string[] = []

  @Watch('data', { immediate: true, deep: true })
  getPropData(v: ChartRes) {
    if (v.id) {
      const { tableData, tableHeader } = formatTbData(v)
      this.$nextTick(() => {
        this.tbData = tableData
        this.thData = tableHeader
      })
      this.loading = false
    }
  }
}
